<form class="col-md-12" [formGroup]="contactForm" (ngSubmit)="sendEmail()">
  <div class="row">
    <div class="form-group col-md-6">
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Name"
        class="form-control"
        formControlName="name"
        [ngClass]="{
          'error-border-color':
            contactForm.controls['name'] &&
            contactForm.controls['name'].touched &&
            contactForm.controls['name'].errors
        }"
      />
      <ng-container
        *ngIf="
          contactForm.controls['name'] &&
          contactForm.controls['name'].touched &&
          contactForm.controls['name'].errors
        "
      >
        <div class="validation-errors">
          <small *ngIf="contactForm.controls['name'].errors['required']">
            Name is required</small
          >
          <small *ngIf="contactForm.controls['name'].errors['pattern']">
            Enter valid name</small
          >
        </div>
      </ng-container>
    </div>
    <div class="form-group col-md-6">
      <input
        type="tel"
        id="mobileNumber"
        name="mobileNumber"
        placeholder="Mobile Number"
        formControlName="mobileNumber"
        class="form-control"
      />
      <ng-container
        *ngIf="
          contactForm.controls['mobileNumber'] &&
          contactForm.controls['mobileNumber'].touched &&
          contactForm.controls['mobileNumber'].errors
        "
      >
        <div class="validation-errors">
          <small
            *ngIf="contactForm.controls['mobileNumber'].errors['required']"
          >
            Mobile Number is required</small
          >
          <small *ngIf="contactForm.controls['mobileNumber'].errors['pattern']">
            Enter 10 digit Mobile number</small
          >
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email address"
        formControlName="email"
        class="form-control"
      />
      <ng-container
        *ngIf="
          contactForm.controls['email'] &&
          contactForm.controls['email'].touched &&
          contactForm.controls['email'].errors
        "
      >
        <div class="validation-errors">
          <small *ngIf="contactForm.controls['email'].errors['required']">
            Email address is required</small
          >
          <small *ngIf="contactForm.controls['email'].errors['pattern']">
            Enter valid email address</small
          >
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <textarea
        class="form-control"
        id="message"
        name="message"
        placeholder="Message"
        formControlName="message"
        rows="3"
        maxLength="70"
      ></textarea>
      <ng-container
        *ngIf="
          contactForm.controls['message'] &&
          contactForm.controls['message'].touched &&
          contactForm.controls['message'].errors
        "
      >
        <div class="validation-errors">
          <small *ngIf="contactForm.controls['message'].errors['required']">
            Message is required</small
          >
          <small *ngIf="contactForm.controls['message'].errors['maxLength']">
            70 characters are only allowed</small
          >
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <button
        type="submit"
        class="btn btn-primary w-100"
        [disabled]="contactForm.invalid"
      >
        Contact Us
      </button>
    </div>
  </div>
</form>
