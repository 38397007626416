<section id="home">
  <div class="parallel-background">
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap h-100"
    >
      <div class="home-codeus-title font-weight-bold">
        <span style="text-shadow: 0px 2px 0px #fff"> CodeUS </span>
        <div class="home-codeus-slogan">
          Creative solutions, creative results.
        </div>
      </div>
    </div>
  </div>
</section>
<section id="about">
  <div class="text-center">
    <div class="about-section-title d-inline-block">
      <h2>About</h2>
      <hr class="small" />
    </div>
  </div>

  <div class="about-codeus">
    <div class="about-codeus-details">
      CodeUs is ace Institute with aim to bridge the gap between the demands of
      the industry and the curriculum of educational institutions.The institute
      is a platform where young minds are given the opportunity to build
      successful careers."To succeed in your mission, you must have
      single-minded devotion to your goal." - A. P. J. Abdul Kalam
    </div>
    <div>
      <img
        src="../../assets/images/binary-laptop.png"
        alt="img"
        class="w-100 binary-laptop-img"
      />
    </div>
  </div>
</section>
<section id="courses">
  <div class="text-center">
    <div class="contact-section-title d-inline-block">
      <h2>Courses</h2>
      <hr class="small" />
    </div>
  </div>
  <div class="text-center" [ngClass]="{ row: isBigScreen }">
    <div class="col d-inline-block"></div>
    <div class="col d-inline-block">
      <span
        class="courses-category-image"
        role="img"
        aria-label="C Programming"
        style="background-image: url('../../assets/images/c-card.png')"
      ></span>
      <div>
        <h4><strong>C Programming</strong></h4>
        <p>
          C Programming will shape your basics which will help to excel in
          academics and helps building strong base for your programming career
        </p>
      </div>
    </div>
    <div class="col d-inline-block borders-courses">
      <span
        class="courses-category-image"
        role="img"
        aria-label="C++/Java"
        style="background-image: url('../../assets/images/java-card.png')"
      ></span>
      <div>
        <h4><strong>C++/Java</strong></h4>
        <p>
          Kick start for the next gear which will introduce to Advance world of
          Programming
        </p>
      </div>
    </div>
    <div class="col d-inline-block">
      <span
        class="courses-category-image"
        role="img"
        aria-label="HTML/CSS/FullStack"
        style="background-image: url('../../assets/images/html-card.png')"
      ></span>
      <div>
        <h4><strong>HTML/CSS/FullStack</strong></h4>
        <p>
          Interactive part which help you to start think about Technology world
          creatively
        </p>
      </div>
    </div>
    <div class="col d-inline-block"></div>
  </div>
</section>

<section id="contactUs">
  <div class="text-center">
    <div class="contact-section-title d-inline-block">
      <h2>Contact</h2>
      <hr class="small" />
    </div>
  </div>
  <div class="contact-codeus">
    <div class="about-contact-us-details">
      <div>Sai Accountables,</div>
      <div>1st Floor,</div>
      <div>London Opticals</div>
      <div>Near Alankar Theatre</div>
      <div>Udupi</div>
      <div>
        <a href="tel:+91-8095834101"
          ><i class="fa fa-phone p-2"></i
          ><span dir="ltr">+91-8095834101 </span>&nbsp;-&nbsp;<span
            >7760247101</span
          ></a
        >
      </div>
      <div>
        <a href="mailto:codeus@gmail.com"
          ><i class="fa fa-envelope-o p-2"></i>codeus@gmail.com</a
        >
      </div>
      <div><i class="fa fa-clock-o p-2"></i>Monday - Friday</div>
      <div>9.00 AM - 6.00 PM</div>
    </div>
    <div class="contact-form">
      <codeus-contact-form></codeus-contact-form>
    </div>
  </div>
</section>
