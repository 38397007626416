import { ApiService } from './../shared/services/http.service';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'codeus-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  contactForm: FormGroup = this.fb.group({
    name: new FormControl('', [
      Validators.pattern('[a-zA-Z ]{1,}'),
      Validators.required,
    ]),
    mobileNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('[6-9]{1}[0-9]{9}'),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '[a-zA-Z0-9]{1,}[@]{1}[a-zA-Z0-9]{2,}[.]{1}[a-zA-Z0-9]{2,}'
      ),
    ]),
    message: new FormControl('', [
      Validators.required,
      Validators.maxLength(70),
    ]),
  });

  constructor(private fb: FormBuilder, private api: ApiService) {}
  sendEmail() {
    if (this.contactForm.invalid) return;
    this.api.postData('contactus.php', this.contactForm.value).subscribe(
      (response) => {
        if (response.success) {
          this.contactForm.reset({
            name: '',
            mobileNumber: '',
            email: '',
            message: '',
          });
        }
        alert(response.message);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
