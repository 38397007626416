import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'codeus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.setHeader();
  }
  toggled = false;
  constructor(private eleRef: ElementRef) {}
  setHeader() {
    let fixedNavbar =
      this.eleRef.nativeElement.querySelectorAll('.fixed-navbar');
    if (
      window.innerWidth >= 768 &&
      fixedNavbar &&
      fixedNavbar.length > 0 &&
      window.innerHeight > window.pageYOffset + fixedNavbar[0]?.offsetHeight
    ) {
      // offsetHeight
      fixedNavbar[0].classList.remove('fix-header-to-top');
      fixedNavbar[0].classList.add('fix-header-to-bottom');
    } else if (
      (fixedNavbar && fixedNavbar.length > 0) ||
      window.innerWidth < 768
    ) {
      fixedNavbar[0].classList.add('fix-header-to-top');
      fixedNavbar[0].classList.remove('fix-header-to-bottom');
    }
  }
  ngOnInit(): void {
    this.setHeader();
  }
  toggleMenu() {
    let nav = this.eleRef.nativeElement.querySelectorAll('.navbar-collapse');
    console.log(nav);
    if (nav && nav.length > 0) nav[0].classList.toggle('show');
  }
}
