import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'codeus-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onScroll() {
    this.setDivsForServices();
  }
  isBigScreen = false;
  constructor() {}

  ngOnInit(): void {
    this.setDivsForServices();
  }
  setDivsForServices() {
    this.isBigScreen = window.innerWidth >= 768;
  }
}
