<div class="footer">
  <footer>
    <ul class="footer-links text-center p-0">
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#courses">Courses</a></li>
      <li><a href="#contactUs">Contact</a></li>
    </ul>
  </footer>
  <div class="copy-right">Copyright © 2022 All rights reserved - CodeUs</div>
</div>
