<nav
  class="navbar navbar-expand-lg navbar-dark fixed-navbar fix-header-to-bottom"
>
  <a class="navbar-brand" href="https://codeus.co.in/">
    <img
      src="../../assets/images/logo.png"
      alt="logo"
      style="max-width: 130px"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navHeaderCollapse"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleMenu()"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div
    class="collapse navbar-collapse justify-content-end"
    #navbarSupportedContent
    id="navbarSupportedContent"
  >
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#" (click)="toggleMenu()">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#about" (click)="toggleMenu()">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#courses" (click)="toggleMenu()">Courses</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#contactUs" (click)="toggleMenu()">Contact</a>
      </li>
    </ul>
  </div>
</nav>
